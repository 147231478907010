.detalhe .ligacao h1{
    text-align: left;
}

.artigo-info,.artigo-info p {
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #737373;
  }
  .artigo-info a,.artigo-info a:hover {
    text-decoration: none;
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #1ABFD5;
  }
  .artigo-info a:hover{
      text-decoration: underline;
  }

  .artigo-info li {
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #737373;
  }


  .actividade .image-artigo img{
       
    height:470px;
    max-width: 470px;
    object-fit: cover;
  
}

.text-align-left h1{
  text-align: left;
}

h5.type{
  margin-top:20px;
  color:#1ABFD5;
  font-weight: bold;
}