/* Ligações Externas */
.ligacao h1{
    padding-top: 33px;
    color:#C1D837;
    text-align: center;
    font-family: 'Andada Pro', serif;
    font-size: 46px;
    font-weight: 700;
}
.ligacao hr{
    border: 2px solid #4BC7D8;
}
.ligacao{
    margin-top: 10rem;
    margin-bottom: 5rem;
}
.link-card{
   /*min-width: 26.4rem;*/
}
.link-card-small{
    /*min-width: 26.4rem;*/
}
.card-link{
    height: 18.5rem;
    filter: drop-shadow(6px 6px 8px rgba(00, 00, 000, 0.08));
    border: none;
    margin-bottom:1.5rem;
}
.ligacoes, .h3{
    color:#3E3E3E;
    font-family: 'Andada Pro', serif;
    font-size: 32px;
    font-weight: 700;
}
.ligacoes-small, .h3{
    color:#3E3E3E;
    font-family: 'Andada Pro', serif;
    font-size: 32px;
    font-weight: 700;
}
.ligacoes a{
    text-decoration: none;
    color: #3E3E3E;;
}
.ligacoes-small a{
    text-decoration: none;
    color: #3E3E3E;;
}
.color{
    filter: invert(19%) sepia(61%) saturate(4%) hue-rotate(339deg) brightness(96%) contrast(87%);
}

.ligacoes .card-body{
    margin-top:58px;
}
.ligacoes a:hover{
    color:#C1D837;
}