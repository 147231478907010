body{
    /*font-size: 20px;*/
    font-weight: 400;
    color: #737373;
    font-family: 'Noto Sans Display', sans-serif;
   /* background:#000;*/
   outline: none;
}

@media (max-width: 460px) {
    .btn-voltar{
        display:none;
    }
 }


/*xs*/
@media (min-width: 576px) {
    .container{
        width:100%!important;
    }
 }
/*sm*/
@media (min-width: 768px) {
    .container{
    width:100%!important;
    } 
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

    .container{
        width:100%!important;
    }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { 
    .container{
        width:1000px !important;
    }
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) { 
    .container{
        width:1200px !important;
    } 
}

.main-title{
    margin-top:10px;
    padding-top:0px!important;
}
h4.main-type{
    margin-top:20px;
    color:#1ABFD5;
    font-weight: bold;
}

.linha-bread-crumbs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;;
    align-items: center;
    margin-top:4rem;
}
#btn-voltar,.btn-voltar,
#btn-voltar:hover,.btn-voltar:hover{
    background:#1ABFD5;
    color:white;
    height:36px;
    border-radius:20px;
    padding-left:20px;
    padding-right:20px;

    
}


.main-title-home{
    margin-top:4rem;
}

.image-artigo img {
    height: 800px;
    max-width: 470px;/**/
    object-fit: cover;
  }

  /*sm*/
@media (max-width: 768px) {
    .image-artigo {
    display:none;
    } 
}



.card{
    border:none !important;
}

.detalhe h1{
    margin-top: 3rem;
}
.ligacao{margin-bottom:3rem;}

.artigo-info h3{
    margin-top:3rem;
    margin-bottom:1rem;
    /*font-family: 'Andada Pro', sans-serif;*/
    
}

.detalhe h2{font-size:20px;/*color:#737373;*/font-family: 'Noto Sans', sans-serif;}
.contactos-info h2{
    font-size: 24px;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    color: #C1D837;
    text-align: left;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-top: 0;
    margin-bottom: .5rem;
    text-transform: uppercase;
}

#btn-voltar1{
    margin-top:1rem;
}
.contato p{
    margin:0 0 0 0;
}
.row.ligacao p{
    color:black;
}