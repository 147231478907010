.breadcrumbs{
    margin:0;
    
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    color: #737373;
}

.breadcrumbs li{
    font-family: 'Noto Sans Display', sans-serif;
font-size: 16px;
color: #737373;
text-decoration: none;
    margin:10px 5px 10px 0px;
}

.breadcrumbs a{
font-family: 'Noto Sans Display', sans-serif;
font-size: 16px;
color: #737373;
text-decoration: none;
}
.breadcrumbs a:hover{
    color: #737373;
    text-decoration: underline;
}
.breadcrumbs .separador{
    margin:0px 10px;
}


.breadcrumbs button{
    z-index:2000!important;
}