.tabela2{
    background-color: #fff;
    filter: drop-shadow(6px 6px 16px rgba(00, 00, 000, 0.16));
    border-radius: 10px;
}

.tabela-titulo {
    color: #1ABFD5;
    font-family: 'Andada Pro', serif;
    font-weight: 700;
    font-size: 32px;

    margin-bottom: 2rem;
    border-color: #1ABFD5;
    margin-top: 4rem;
  }

  .tabela2 th {  
    color: #1ABFD5;
    font-family: 'Andada Pro', serif;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2rem;
    vertical-align: middle;
    padding: 2rem;
    border:none;
  }

  .tabela2 thead th,
  .tabela2 tbody td{
      padding: 2rem;
  }

  .tabela2 thead tr{
    border-bottom: 2px solid #1ABFD5;
}
.tabela2 tbody tr{
    border-bottom: 1px solid #C1D837;
}


.tabela2 td {
    color: #737373;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 2rem;
    border-color: #C1D837;
    padding: 2rem;
    vertical-align: middle;
  }

  .tabela2 .rodape{
    padding: 2rem;
  }
  .tabela2 .rodape .tligacoes,
  .tabela2 .rodape .tligacoes a,
  .tabela2 .rodape .tresponsavel{
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
  }
  .tabela2 .rodape .tligacoes span:first-child,
  .tabela2 .rodape .tresponsavel span:first-child{
      margin-right:10px;
  }
  .tabela2 .rodape .tligacoes a {
      margin-right:10px;
  }
  
  .tabela a ,  .tabela2 a,
  .tabela a:hover ,  .tabela2 a:hover {
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #1ABFD5;
    text-decoration: none;
    margin-bottom: 4rem;
  }
  .table2{
    width:100%;
  }

  .plano-plurianual span {
    color: #3D3D3D;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    font-size: 32px;
  }
  .tabela-titulo h3 span a:link,
  .tabela-titulo h3 span a:visited,
  .tabela-titulo h3 span a:hover,
  .tabela-titulo h3 span a:active{
    color:#1ABFD5;font-family: 'Andada Pro', serif;
    font-size:20px;
    margin-left:20px;
    text-decoration: none;
  }


  .artigo-info.plano-plurianual h4{
    font-family: 'Andada Pro', 'sans-serif';
    font-size:24px;
    margin-bottom:1rem;
  }

  .artigo-info.plano-plurianual h5{
    font-family: 'Andada Pro', 'sans-serif';
    font-weight:bold;
    margin-bottom:1rem;
  }

  .borderBottomResponsavel{
    border-bottom: 4px solid  #4BC7D8!important;
    margin-left:-32px;
    margin-right:-32px;
  }