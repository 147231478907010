/* Cookies */

#cookie-banner {
    font-size: 16px;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 600;
    color: #3D3D3D;
    background-color: #F5F5F5; /* #EBEBEB; /* #C1D837; */
    border: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
    /*height: 50px;*/
  width: 100%;
  z-index: 99;
  border-radius: 0px;
  padding-top:0.3rem;
}
#cookie-banner a{
    color:  #4BC7D8;
    text-decoration: none;
}
#cookie-banner .btn-primary{
    background-color:#4BC7D8;
    border-color: #4BC7D8;
    height: 40px;
    width: 100px;
    font-size: 16px;
    font-weight: 600;
    float: right;
}
