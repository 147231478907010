/* Slider */
.slider .carousel{
    margin-top: 2rem;
}
.image-over,.image-over2{
    height:300px;
    position:relative;
    overflow:hidden;
}
@media (min-width: 576px) { 
    .image-over,.image-over2{
        /*height:300px;*/
        height:400px;
    }
    
 }

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
    .image-over,.image-over2{
        /*height:400px;*/
        height:527px;
       
    }
 }

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
    .image-over,.image-over2{
        height:527px;
    }
    .slide-contain{
        
    }
 }
/******************************************************/
.slide-contain{
   /* min-height: 799px;*/
    max-height: 100%;
}
.slide-contain img{
    width: 100%;
    height: 100%;
    object-fit:cover; /* Fill */
}
.slide-over img{
    object-fit:fill;
}


/* Slide Info */
.atividade, .informacoes{
    margin-top: 2.5rem;
    margin-bottom:0;
}
.atividade h3{
    text-align: center;
    font-family: 'Andada Pro', serif;
    font-size: 46px;
    font-weight: 700;
    color:#C1D837;
}
.informacoes h3{
    text-align: center;
    font-family: 'Andada Pro', serif;
    font-size: 46px;
    font-weight: 700;
    color:#C1D837;
}
.atividade hr,
.informacoes hr{
    border: 2px solid #4BC7D8;
}

.slide-text{
    min-height: 105px;
    overflow: hidden;
}
.slide-info h5{
    font-family: 'Andada Pro', serif;
    font-size: 24px;
    font-weight: 600;
    color: #1ABFD5;
    padding-top: 1rem;
    min-height: 75px;
    overflow: hidden;
}
.slide-info p{
    font-family:'Noto Sans Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.slide-info a{
    padding:0;
    text-decoration: none;
    font-family:'Noto Sans Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color:#1ABFD5;
}
.data{
    position: absolute;
    width: 120px !important;
    max-width: 100%;
    margin-right: 0.05rem;
    height: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 2;
    text-align: center;
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1));
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
    text-transform: capitalize;
}
.data p{
    font-family:'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #3D3D3D;
}
.slide-title{
    min-height:59px;
}
.slide-text{
    min-height:120px;
}

/* Button */
.lermais2{
    text-align: center;
}
.lermais2 h4{
    font-weight: 600;
    padding:0;
    font-size: 20px;
    color: #58595B;
}
.btn-new2{
    height: 50px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 80px;
}
.btn-new2:hover{
    height: 50px;    
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 80px;
    fill: #C1D837;
}

/* Overlay */
.slide-img-over{ 
    position: relative;
    bottom:-3px;
}
.slide-over{
    position: absolute;
    /*top: 0%;*/
    bottom: -2%;
    /*left: 0;*/
    right: -1px;/*
    height: 100%;
    width: 100%;*/
    opacity: 0;
    transition: .3s ease;
}
.image-over:hover .slide-over{
    opacity:0.64;
}
.slide-img-over2{ 
    position: relative;
}
.slide-over2{
    position: absolute;
    /*top: 20%;*/
    bottom: -2%;
    left: 0;
    /*right: 20%;
    height: 80%;
    width: 80%;*/
    opacity: 0;
    transition: .3s ease;
}
.image-over2:hover .slide-over2{
    opacity:0.64;
}

#carouselActividades .slide-contain{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.tns-outer{
    position:relative;

}

.tns-controls{
    position:absolute;
   display:flex;
   flex-direction: row;
   justify-content: space-between; 
    width:100%;
    height: 100%;
    
}
.tns-controls button{
    z-index:3;
    background: transparent;
    border:none;
    opacity:0.5;
}
.tns-controls button:hover{
    filter: drop-shadow(2px 2px 6px rgba(256, 256, 256, 0.5));
    opacity:1;
}
.tns-controls button:first-child{
padding-left:15px;
}

.tns-controls button:last-child{
    padding-right:15px;
}


.slide-contain .image-over .slide-over{
    width:60%;
    height:auto;
}

.slide-contain:nth-child(2) .image-over .slide-over,
.slide-contain:nth-child(4) .image-over  .slide-over,
.slide-contain:nth-child(6) .image-over  .slide-over,
.slide-contain:nth-child(8) .image-over  .slide-over,
.slide-contain:nth-child(10) .image-over  .slide-over{
      left:0px;

}
.slide-contain:nth-child(2) .image-over .slide-over .slide-image-over,
.slide-contain:nth-child(4) .image-over  .slide-over .slide-image-over,
.slide-contain:nth-child(6) .image-over  .slide-over .slide-image-over,
.slide-contain:nth-child(8) .image-over  .slide-over .slide-image-over,
.slide-contain:nth-child(10) .image-over  .slide-over .slide-image-over{
      transform: scaleX(-1)!important;

}