.btn.btn-erro,
.btn.btn-erro:hover{
    background-color: #C1D837;
    color:white;
    border-radius: 20px;
    padding: 8px 44px;
    margin-top:6rem;
}


.erro h1{
    color:#1ABFD5;
    font-weight:700;
    font-size: 36px;
    
}


.erro h3{
    font-size:20px;
    margin: 2rem 0 0 0;
    color: #737373;
}
.erro{
    margin: 6rem 0;
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 768px) {
    .erro h3{
        font-size:20px;
        margin: 6rem 0 0 0;
        color: #737373;
    }
}