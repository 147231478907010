.contactos-info h2 {
    font-size: 24px;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    color: #C1D837;
    text-align: left;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-top: 0;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }
.contactos-info h3{
    color:#58595B
}
.contactos-info p,
.contactos-info span,
.contactos-info a
  {
      color:#737373;
    font-weight: 400;
    font-size: 20px;
    margin: auto 0;
  }
  .contactos-info a,
  .contactos-info a:link,
  .contactos-info a:visited,
  .contactos-info a:hover,
  .contactos-info a:active{
color:#1ABFD5;

  }

  .contacto{
      margin-bottom:2rem;
  }