/* Noticias */
.noticias-anucios .info{
    margin-bottom: 2rem;
}
.noticias-anucios .card-col{
   /* min-width:26rem;*/
}

.noticias-anucios .card-thin{
    min-height:220px;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    
}
.noticias-anucios .card-thin, .img-over{ 
    position: relative;
}
.noticias-anucios .card-thin{
    margin-top: 4rem;
}
.noticias-anucios .img-over img{
    object-fit: cover;
    
}
.noticias-anucios .overlay-img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    bottom: 0;
    right:0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
}
.noticias-anucios .overlay-img img{
    position:absolute;
    max-height:100%;
    width:auto;
    bottom:-4px;
    right:0px;

}
.noticias-anucios .img-over:hover .overlay-img{
    opacity:0.64;
}
.noticias-anucios .news{
    padding-top: 1rem;
    padding-right:0;
    padding-left:0;
    max-height: 16.875rem;
    height: 100%;
}
.noticias-anucios .news h4{
    font-size: 20px;
    font-family: 'Noto Sans Display', sans-serif;
    color: #58595B;
    margin-bottom: 0;
    padding:0;
}
.noticias-anucios .news .card-title{
    min-height: 3.75rem;
}
.noticias-anucios .news h5{
    font-size:22px;
    font-family:'Andada Pro', serif;
    font-weight:600;
    color:#1ABFD5;
    overflow:hidden;
}
.noticias-anucios .news a{
    padding:0;
    text-decoration: none;
    font-family:'Noto Sans Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color:#1ABFD5;
}
.noticias-anucios .news .card-text{
    height: 6.25rem;
    color:#737373;
    overflow:hidden;
}
.noticias-anucios .vermais{
    text-align: center;
}
.noticias-anucios .lermais{
    text-align: center;
}
.noticias-anucios .lermais .a{
    padding: 0px;
}
.noticias-anucios .lermais h4{
    font-weight: 600;
    font-size: 20px;
    color: #58595B;
}
.noticias-anucios .btn-new1{
    height: 50px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 80px;
}
.noticias-anucios .btn-new1:hover{
    height: 50px;
    background-size: 80px;
    fill: #C1D837;
}

.noticias-anucios .btn-big-lermais{
    margin-top: 8rem;
    margin-bottom: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    height: 130px;
    width: 172px;
    background-size: 171px;
    font-size:20px !important;
    font-family:'Noto Sans Display', sans-serif;
    font-weight:600 !important;
    color: white !important;
}
.noticias-anucios .btn-big-lermais:hover{
    
    height: 130px;
    width: 172px;
    background-size: 171px;
    color: white;
}

.card-col .card-img-top{
    right:0px!important;
}
.card-col{
    cursor:pointer;
}
.card-col:nth-child(2) .card-img-top,
.card-col:nth-child(4) .card-img-top,
.card-col:nth-child(6) .card-img-top,
.card-col:nth-child(8) .card-img-top,
.card-col:nth-child(10) .card-img-top,
.card-col:nth-child(12) .card-img-top,
.card-col:nth-child(14) .card-img-top{
    left:0px!important;
    transform: scaleX(-1)!important;

}