.pesquisa{
    margin-top:40px;
}

.pesquisa .input-group{
border: 2px solid black;
border-radius: 26px 26px;
padding:5px 2px 5px 5px;
}
.pesquisa input {
    border:none;
    margin-left:10px;
    margin-right:10px;
    height:32px;
    box-shadow: none!important;
}
.pesquisa button.btn.btn-pesquisa.rotate-45 i{
    transform:rotate(45deg);
}
.pesquisa button.btn-pesquisa,
.pesquisa button.btn-pesquisa:hover,
.pesquisa button.btn-pesquisa:active,
.pesquisa button.btn-pesquisa:focus{
/*z-index:2;*/  
border-radius: 50% 50% 50% 50%!important;
border:none!important;
background-color: transparent !important;;

outline: none!important;
box-shadow: none!important;
height:32px;
top:-7px;
right:-9px;
}
.pesquisa i{
    font-size:36px;
     
} 
.pesquisa button.btn-pesquisa i,
.pesquisa button.btn-pesquisa:hover i{
    color: #000 ;
}




.container_pagination{
    align-self: center;
    margin:0 auto;
    display: flex;

    list-style: none;

}


.page_pagination{

	

    border:none;
    color:#777777;
    /*/border: 1px solid #dcdcdc;

    border-radius: 6px;*/

    margin-right: 10px;

    cursor: pointer;

}
.page_pagination a{

	padding: 5px;
    color:#777777;

}


.disabled_pagination{

    cursor: not-allowed;

    

}


.active_pagination{

	/*border: 2px solid #000;*/
    color:#000;

    font-weight: bold;

}


.container_pagination .previous{

    float:left;
    padding: 5px;

    border-radius: 6px;

    margin-right: 10%;

    cursor: pointer;

}


.break_pagination{

    padding: 5px;

}

.container_pagination a{
    display:block
}
.container_pagination .next{

    padding: 5px;

    border-radius: 6px;

    margin-left: 10%;

    cursor: pointer;

}

.pesquisa h1{
    font-size:22px;
    color:#59cbdb;
}
.pesquisa ul{
    margin:0;
    padding:0;
    list-style-type: none;
    border:none!important;
}

li.resultado_pesquisa{
    padding:0;
    display:flex;
    flex-direction: column;
    border:none!important;
}
.resultado_pesquisa .img-thumbnail{
   /* max-width:150px!important;
    max-height:150px!important;*/
    border:none !important;
}

.resultado_pesquisa h2{
    /*titulo*/
    font-size:22px;
    font-weight: bold;
    color:#1ABFD5;
}

.resultado_pesquisa h6{
    /*bottom:0px;*/
}
.resultado_pesquisa h6 b{
    /*titulo*/
    font-size:14px;
    color:#333;
}

.resultado_pesquisa h6 span{
    /*titulo*/
    font-size:14px;
    color:#999999;
}

.resultado_pesquisa h4,
.resultado_pesquisa h4 *{
    font-size:16px;
    color:#333;
}



.resultado_pesquisa .col-4.col-md-2{
    align-items: center;
}
li.resultado_pesquisa:first-child{
    border-top:2px solid #1ABFD5!important;
    padding:1rem 0px 0.4rem 0px!important;
    border-radius: 0 0 0 0!important;
}
li.resultado_pesquisa{
border-top:2px solid #c5c5c5!important;
padding:1rem 0px 0.4rem 0px!important;
border-radius: 0 0 0 0!important;
}

.resultado_pesquisa .btn.btn-default{
    background-color: #1ABFD5;
    color:white;
    border-radius: 25px;
    margin-top:-44px;
    padding-left:30px;
    padding-right:30px;
}


/**/
#Navigation0 button.btn.btn-default{
    color:#000;
    background:white;
    cursor:pointer;
    padding:0 0 0 0;
}
#Navigation0 button.btn.btn-default i{
    color:#000;
    font-size:22px;
    font-weight:bold;
}
#Navigation0{
   display: flex;
    
}
div.pesqTit{
padding:10px 0px;
 display: flex;
 flex-direction: row;  
 justify-content: space-between;
 align-items: center;   
}
div.pesqTit h1{
    display:flex;
}

.list-group-item.resultado_pesquisa > :nth-child(2){
    margin-top:1rem;
}

.resultado_pesquisa .label-publish-date{
    margin-right:20px;
}

.input-group .btn {
    position:initial!important;
    z-index:0!important;
  }
#button-addon2.btn.btn-pesquisa{padding:0!important}