
.listagem .card-thin {
margin-top: 4rem;
}
.listagem .card-thin, .img-over {
position: relative;
overflow:hidden;
}
.listagem .card-thin {
height: 40rem;
}

.listagem .info{
margin-bottom: 2rem;
}

.listagem .card-thin, .img-over{ 
    position: relative;
}

.listagem .img-over img{
    object-fit: cover;
    
}

.listagem .overlay-img img{
    bottom: -4px;
    max-height: 100%;
    position: absolute;
    right: 0;
    width: auto;
}

.listagem .overlay-img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
  
   
  /*  position: absolute;
    bottom: -4px;
    opacity: 0;
    transition: .3s ease;*/
}

.listagem .img-over:hover .overlay-img{
    opacity:0.64;
}
.listagem .news{
    padding-top: 1rem;
    padding-right:0;
    padding-left:0;
    max-height: 16.875rem;
    height: 100%;
}
.listagem .news h4{
    font-size: 20px;
    font-family: 'Noto Sans Display', sans-serif;
    color: #58595B;
    margin-bottom: 0;
    padding:0;
}
.listagem .news .card-title{
    height: 3.75rem;
}
.listagem .news h5{
    font-size:22px;
    font-family:'Andada Pro', serif;
    font-weight:600;
    color:#1ABFD5;
    overflow:hidden;
}
.listagem .card-text a{
    padding:0;
    text-decoration: none;
    font-family:'Noto Sans Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color:#1ABFD5;
}
.listagem .card-text{
    height: 6.25rem;
    color:#737373;
    overflow:hidden;
}
.listagem .lermais{
    text-align: center;
}

.listagem .card-col:nth-child(1) .img-over .overlay-img img,
.listagem .card-col:nth-child(3) .img-over .overlay-img img,
.listagem .card-col:nth-child(5) .img-over .overlay-img img,
.listagem .card-col:nth-child(7) .img-over .overlay-img img,
.listagem .card-col:nth-child(9) .img-over .overlay-img img,
.listagem .card-col:nth-child(11) .img-over .overlay-img img,
.listagem .card-col:nth-child(13) .img-over .overlay-img img,
.listagem .card-col:nth-child(15) .img-over .overlay-img img,
.listagem .card-col:nth-child(17) .img-over .overlay-img img,
.listagem .card-col:nth-child(19) .img-over .overlay-img img,
.listagem .card-col:nth-child(21) .img-over .overlay-img img,
.listagem .card-col:nth-child(23) .img-over .overlay-img img{
    right:-6px;
}
.listagem .card-col:nth-child(2) .img-over .overlay-img,
.listagem .card-col:nth-child(4) .img-over .overlay-img,
.listagem .card-col:nth-child(6) .img-over .overlay-img,
.listagem .card-col:nth-child(8) .img-over .overlay-img,
.listagem .card-col:nth-child(10) .img-over .overlay-img,
.listagem .card-col:nth-child(12) .img-over .overlay-img,
.listagem .card-col:nth-child(14) .img-over .overlay-img,
.listagem .card-col:nth-child(16) .img-over .overlay-img,
.listagem .card-col:nth-child(18) .img-over .overlay-img,
.listagem .card-col:nth-child(20) .img-over .overlay-img,
.listagem .card-col:nth-child(22) .img-over .overlay-img,
.listagem .card-col:nth-child(24) .img-over .overlay-img{
    left:-1px;
    right:50%;

}
