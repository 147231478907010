    .noticia .image-artigo img{
       
            height:470px;
            max-width: 470px;
            object-fit: cover;
          
    }

.text-align-left h1{
        text-align: left;
}

h5.type{
        margin-top:20px;
        color:#1ABFD5;
        font-weight: bold;
}