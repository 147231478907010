/* Footer */
.foot{
    margin-top:8.5rem;
    margin-bottom:2rem;
    color: white;
}
.fa-facebook-f, .fa-twitter, .fa-linkedin-in, .fa-youtube, .fa-phone-alt{
    font-size: 29px;
    /* padding: 1.25rem; */
    cursor: pointer;
}
.foot-links{
    display: flex;
    justify-content: start;
    margin:2rem 0;
}
.foot a{
    color: white;
    text-decoration: none;
}
.foot a:hover{
    color: #1ABFD5;
}
.fundo{
    /*width: 100%
    margin: 0;*/
}
/* Accordion */
.accordion1{
    margin-top: 4rem;
    min-width:18.8rem;
    
    
}
.accordion1 .accordion{    
   
}
.accordion1 .accordion-item{
background:transparent;
   border: none;
    
}

.accordion1 .accordion-header{
    /*border-bottom:1px solid #fff;*/
}
.accordion1 .accordion-header button:focus{
    box-shadow: none;
}
.accordion1 .accordion-body{
    /*background:#fff;*/
    border-top: 1px solid #fff;
    
}
.accordion2{
    margin-top: 8.5rem;
    min-width: 18.8rem;
} 
.accordion1 h3{
    font-family: 'Andada Pro', serif;
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    margin-bottom: 2rem;
}
.accordion-header{
    padding-top: 0;
}
.accordion-button{
    background-color: #4BC7D8;
    color: #fff;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.accordion-button.collapsed{
    background-color: #4BC7D8;
    color: #fff; 
}
.accordion-body{
    color: #58595B;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 16px;
    word-break:break-all;
}
.accordion-body p{
    padding: 5px;
    margin: 0;
}
.fundo2{
    display:flex;
    background-color: #EBEBEB;
    align-items: center;
    max-width: 100%;
    margin: 0;
}
.fundo2 h5{
    font-size: 16px;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 700;
}
.legal{
    align-self: right;
}
.legal a,
.legal a:hover{
    text-decoration: none;
    color:#3D3D3D;
    font-size: 16px;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 700;
}



.accordion-button.collapsed {
    background-color: #C1D837;  
    color: #fff;
  }

  .accordion-button::after {
    
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    
  }

  .accordion-button:not(.collapsed) {
    color: #ffffff !important;
    background-color: transparent !important ;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
  }

  .accordion-button:not(.collapsed):hover,
  .accordion-button:hover{
    color: #ffffff !important;
    background-color: #cfda90 !important;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
  }
  .foot{
      font-size:16px;
  }
  .foot p{
    margin:0 0 0 0;
}

.accordion1 h3{
    padding-left: 19px;
}

.alinhamento{
    text-align: right;
}

@media (max-width: 700px) {
    .legal a, .legal a:hover {
        font-size:12px;
      }

    .alinhamento{
        text-align: left;
    }
}

.accordion-body a,
.accordion-body a:hover{
    color:#58595b;
}


p.info_name{
    font-size:1.25rem;
    font-weight:bold;
    margin-bottom:12px;
}
