.name h2 {
    font-size: 24px;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    color: #3D3D3D;
  }

  .artigo-info h3:first-child{
    margin-top:0px;
  }

  .representantes {
    color: #1ABFD5;   
    margin-bottom: 3rem;
    font-family: 'Andada Pro', serif !important;
    font-size: 32px !important;
    font-weight: 700;
  }