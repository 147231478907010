.detalhe .ligacao h1{
    text-align: left;
}

.artigo-info p {
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #737373;
  }
  .artigo-info a,.artigo-info a:hover {
    text-decoration: none;
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #1ABFD5;
  }
  .artigo-info a:hover{
      text-decoration: underline;
  }

  .artigo-info li {
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #737373;
  }