/* Carousel */
.btn-projeto{
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1));
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
    border:none;
   /* background: none; */
}
.btn-projeto a{
    text-decoration: none;
    font-size: 26px;
}
/* .projeto-teip{
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1));
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
} */
.image1-proj{
    max-width:200px;
    padding: 10px;
}
.image2-proj{
    max-width:100px;
    padding:10px;
}
.carousel{
    margin-top: 40px;
}
.carousel-inner>img{
    width: 100%;
    height: 500%;
}

.overlay:after {
  content:'';
  position:absolute;
  left: 0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.5) 100%);
}


/* Carousel Controls */
#carouselExampleCaptions .carousel-control-prev{
    top: 42%;
    left: 5%;
    /*background: url("SVGs/folha_navegacao_esq.svg") center no-repeat;*/
    background-position: center;
    background-repeat:no-repeat;
    height: 50px;
    width: 50px;
    background-size: 50px;
    opacity: 1;
}
#carouselExampleCaptions .carousel-control-next{
    top: 42%;
    right: 5%;
    /*background: url("SVGs/folha_navegacao_dir.svg") center no-repeat;*/
    background-position: center;
    background-repeat:no-repeat;
    height: 50px;
    width: 50px;
    background-size: 50px;
    opacity: 1;
}
.carousel-control-prev:hover{
    filter: drop-shadow(2px 2px 6px rgba(256, 256, 256, 0.5));
    opacity: 1;
}
.carousel-control-next:hover{
    filter: drop-shadow(2px 2px 6px rgba(256, 256, 256, 0.5));
    opacity: 1;
}
#carouselExampleCaptions .carousel-control-prev{
    display: none;
}
#carouselExampleCaptions .carousel-control-next{
    display: none;
}
.projeto-teip h5,
.projeto-teip p{
    display:none;
}
@media (min-width: 576px) {
    #carouselExampleCaptions .carousel-control-prev{
        display: flex;
    }
    #carouselExampleCaptions .carousel-control-next{
        display: flex;
    }

    .projeto-teip h5,
    .projeto-teip p{
        display:block;
    }
}   


/*tamanhos dos textos*/
/*Small devices (landscape phones, 576px and up)*/
button.btn-projeto {
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1))!important;
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.6);
    border: none!important;
    
}
.btn-projeto a{
    text-decoration: none;
}
@media (min-width: 0px) {
    .carousel-caption{
        text-align: left !important;
        margin-bottom: 0;
        padding:0 !important;
    }
    .carousel h5,.carousel h5 *{
        font-family: Andada Pro;
        font-weight: 800;
        font-size: 24px!important;
    }
    .carousel p,.carousel p *{
        font-family: Andada Pro;
        font-weight: 600;
        font-size: 12px!important;
        margin:0;
    }

    .btn-projeto a{
        text-decoration: none;
        font-size: 12px!important;
    }
 }

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
    .carousel-caption{
        text-align: left !important;
        margin-bottom: 1rem;
        padding:0 !important;
    }
    .carousel h5,.carousel h5 *{
        font-family: Andada Pro;
        font-weight: 800;
        font-size: 30px!important;
    }
    .carousel p,.carousel p*{
        font-family: Andada Pro;
        font-weight: 600;
        font-size: 16px!important;
        margin:0;
    }

    .btn-projeto a{
        text-decoration: none;
        font-size: 16px!important;
    }
}


@media (max-width: 992px) { 
    .carousel{
        margin-top: -137px;
    }
}
/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {    
    .carousel-caption{
        text-align: left !important;
        margin-bottom: 2rem;
        padding:0 !important;
    }
    .carousel h5,.carousel h5 *{
        font-family: Andada Pro;
        font-weight: 800;
        font-size: 40px!important;
    }
    .carousel p,.carousel p *{
        font-family: Andada Pro;
        font-weight: 600;
        font-size: 24px!important;
        margin:0 !important;
    }

    .btn-projeto a{
        text-decoration: none;
        font-size: 24px!important;
    }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { 
    .carousel h5,.carousel h5 *{
        font-family: Andada Pro;
        font-weight: 800;
        font-size: 76px!important;
    }
    .carousel p,
    .carousel p *{
        font-family: Andada Pro;
        font-weight: 600;
        font-size: 24px!important;
    }
    .carousel-caption,.carousel-caption *{
        text-align: left !important;
        bottom:15% !important;
    }
    .btn-projeto a,.btn-projeto a *{
        text-decoration: none;
        font-size: 26px!important;
    }
}





.carousel-caption {
    position: absolute;
    right: 15%;
    /*top: 25%;*/
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
  .carousel-caption {
    top: auto;
}