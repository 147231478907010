.conselho-face {
    position: relative;
}
.conselho-face .img-thumbnail{
    padding: .25rem;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    height: auto;
    display: inline-block;
}
.img-overlay {
    position: absolute;
    /*z-index:2;*/
    bottom: -11px;
    right: -13px;
    transform: rotate(-28deg);
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.3));
  }


  .cartao-face {
    margin-bottom: 2rem;
  }

  .conselho-name{
    font-size: 24px;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    color: #3D3D3D;
  }
  .conselho-cargo{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #737373;
  }

  .conselho-pedagogico h2{
    font-size: 32px;
    font-family: 'Andada Pro', serif;
    font-weight: 700;
    color: #1ABFD5;
  }

.conselho-pedagogico h3{
    margin-top: 2rem;
    font-size: 24px;
    font-family: 'Andada Pro', serif;
    font-weight: 600;
    color: #3D3D3D;
}
.conselho-pedagogico p{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #737373;
}

.lista-coordenadores h2{
  color:#1ABFD5;
  margin-top: 3rem;
margin-bottom: 1rem;
font-family: 'Andada Pro', serif!important;
font-size: 32px!important;
font-weight: 700;
}

.artigo-info.lista-coordenadores .contato{
  margin-top:2rem;

}