.nextprevline .btn-esq{
    text-align:left;
}

.nextprevline .btn-dir{
    text-align:right;
}

.nextprevline{
    margin-top:4rem;
    margin-bottom:32px;
}

.detalhe.nextprevline .botoes .btn.btn-prev{
    padding:0px 0px 0px 60px!important
}
.detalhe.nextprevline .botoes .btn.btn-next{
    padding:0px 60px 0px 0px!important
}