.tabela-nome span {
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #737373;
  }
.tabela {
    background-color: #fff;
    filter: drop-shadow(6px 6px 16px rgba(00, 00, 000, 0.16));
    border-radius: 10px;
}
.tabela .linha {
    border-bottom:2px solid #C1D837;
 }
.tabela thead tr{
   
    border-bottom: 2px solid #1ABFD5;
}
.tabela thead  th{
    padding:2rem 0;
} 

.tabela  th {
    color: #1ABFD5;
    font-family: 'Andada Pro', serif;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2rem;
    border-bottom: 2px solid #1ABFD5;
    vertical-align: middle;
    padding: 2rem;
      padding-right: 2rem;
      padding-left: 2rem;
  }
  .tabela  th:not(:first-child),
  .tabela  td:not(:first-child) {
   text-align: center;
  }


.linha td{
    color: #737373;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    vertical-align: middle;
}

.linha td a{
    color: #1ABFD5;
   /* font-family: 'Andada Pro', serif;*/
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
}
.linha td a:hover{
    color: #1ABFD5;
    /*font-family: 'Andada Pro', serif;*/
    font-weight: 700;
    font-size: 20px;
    text-decoration: underline;
}

.tabela tr td{
    padding:2rem 0;
} 
/* Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
    .tabela tr td{
        padding:0.5rem 0;
    }
    .tabela tr td:first-child
    .tabela tr td:first-child a{
        font-weight: bold !important;
    } 
    .tabela td:not(:first-child) {
        text-align: left;
    }

    .tabela tbody{
        border:0px !important;    
    }
    .tabela thead th,.tabela thead tr {
        display: none;
        border:none;
    }

    .tabela .linha td {
        display: block;
    }
    .tabela.linha  td:not(:first-child) {
        text-align: left;
    }
    .tabela .linha td[data-th]:before  {
        content: attr(data-th);
    }

    .tabela .linha td div{
        float:right;
    }
    .tabela .linha {
        border-bottom:2px solid #C1D837;
     }
}

 