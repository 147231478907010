.video{
    
    text-align: center;
    padding-left:0;
    padding-right:0;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 1245px;
}
.vid{
    margin-top: 4rem;
    background-position: right;
    background-repeat: no-repeat;
}


@media (max-width: 768px){
.vid .row{
    padding-left:0;
    padding-right:0;
}

.container-fluid.padding.my,
.container-fluid.padding.my .container
{
padding-left:0px;
padding-right:0px;
}



}