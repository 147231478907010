.newsletter{
    background-color: #C1D837;
    width: 100%;
    
    padding-bottom: 10rem;
}
.newsletter h2{
    text-align: center;
    font-size: 46px;
    color: #ffffff;
    padding-top:10rem;
}
.newsletter h4{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: 20px;
    color: #ffffff;
  }  
  .newsletter .form-control{
    padding:20px;
}
.newsletter .vermais .btn-primary{
    background-color: #1ABFD5;
    border: none;
}


.captcha canvas{
    width:100% !important;
    height:auto;
}